*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  /* 10px */
  scroll-behavior: smooth; }
  @media only screen and (max-width: 85.5em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 62.5em) {
    html {
      font-size: 40%; } }

body {
  box-sizing: border-box; }

section {
  max-width: 100vw;
  overflow: hidden; }

li {
  list-style: none; }

a {
  text-decoration: none; }

button {
  border: none;
  cursor: pointer; }

::selection {
  background-color: #cc4287;
  color: #fff; }

::-webkit-scrollbar {
  width: 15px; }

::-webkit-scrollbar-track {
  background: #2e2e2e; }

::-webkit-scrollbar-thumb {
  background: rgba(110, 110, 110, 0.5); }

::-webkit-scrollbar-thumb:hover {
  background: #929292; }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-3rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes moveInLeftSpecial {
  0% {
    right: 0;
    opacity: 0;
    transform: skew(-15deg) translateX(-10rem); }
  100% {
    opacity: 0.3;
    transform: skew(-15deg) translateX(-50%); } }

@keyframes moveInRightSpecial {
  0% {
    left: 0;
    opacity: 0;
    transform: skew(15deg) translateX(-10rem); }
  100% {
    opacity: 0.3;
    transform: skew(15deg) translateX(-50%); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes heartbeats {
  0% {
    transform: scale(1);
    color: #b01030; }
  20% {
    transform: scale(1.25);
    color: #dc143c; }
  40% {
    transform: scale(1);
    color: #b01030; }
  60% {
    transform: scale(1.25);
    color: #dc143c; }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1);
    color: #b01030; } }

@keyframes fade-in-out {
  0% {
    opacity: 0; }
  20% {
    opacity: 1;
    bottom: 0; }
  100% {
    opacity: 0;
    bottom: 10rem; } }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.heading-primary {
  position: relative;
  font-size: 7rem;
  opacity: 0;
  animation: moveInLeft 1s ease-out 2.5s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }
  .heading-primary__first-part {
    position: absolute;
    top: 2rem;
    left: -4rem;
    font-weight: 400;
    font-size: 2.5rem; }

.heading-secondary {
  font-size: 4rem;
  font-weight: 300; }

.heading-tertiary {
  color: #fff;
  text-transform: uppercase;
  font-size: 2.5rem; }

.u-center-text {
  text-align: center !important; }

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important; }

.u-margin-bottom-medium {
  margin-bottom: 4rem !important; }

.u-margin-bottom-big {
  margin-bottom: 8rem !important; }

.u-margin-top-medium {
  margin-top: 4rem !important; }

.u-margin-top-big {
  margin-top: 8rem !important; }

.u-margin-top-huge {
  margin-top: 10rem !important; }

.underline-magical {
  background-image: linear-gradient(140deg, rgba(255, 83, 169, 0.6), rgba(130, 114, 224, 0.4));
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in; }
  .underline-magical:hover {
    background-size: 100% 100%; }

.traits {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 10rem;
  width: 70vw;
  min-height: 40rem;
  flex-direction: column;
  font-size: 1.6rem;
  background: linear-gradient(to right, rgba(14, 5, 71, 0.5), rgba(62, 42, 116, 0.6)), url("/src/assets/backgrounds/traits-background.webp");
  background-size: cover;
  background-position: center;
  clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%); }
  @media only screen and (max-width: 87.5em) {
    .traits {
      width: 90vw; } }
  @media only screen and (max-width: 28.5em) {
    .traits {
      padding: 4rem 5rem; } }
  .traits__list {
    display: flex;
    justify-content: center;
    gap: 3rem; }
  .traits__card {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    width: 25rem;
    padding: 2rem;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.9);
    color: #707070;
    transition: transform 0.3s; }
    .traits__card:hover {
      transform: translateY(-1.5rem); }
    .traits__card--icon {
      font-size: 3rem;
      filter: grayscale(50%);
      opacity: 0.5; }
      .traits__card--icon-1 {
        color: #ff7b00; }
      .traits__card--icon-2 {
        color: #dc143c; }
      .traits__card--icon-3 {
        color: #800080; }
      .traits__card--icon-4 {
        color: #daa520; }
    .traits__card--title {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 2rem;
      text-align: center; }
    .traits__card--text {
      letter-spacing: 1.5px;
      line-height: 2.5rem; }
  @media only screen and (max-width: 112.5em) {
    .traits__list {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(2, 1fr); }
    .traits__card {
      width: 90%; }
      .traits__card--icon {
        font-size: 2.5rem; }
      .traits__card--title {
        margin: 0;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 1.6rem; }
      .traits__card--text {
        letter-spacing: inherit;
        line-height: inherit; } }
  @media only screen and (max-width: 31em) {
    .traits__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem; } }
  @media only screen and (max-width: 28.5em) {
    .traits {
      clip-path: none; }
      .traits__card {
        width: 110%; } }

.vignettes {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  color: #656565; }
  .vignettes > * {
    padding: 3rem 5rem;
    max-width: 35rem;
    background-color: #eee; }
  .vignettes--1 {
    clip-path: polygon(0 5%, 95% 0, 95% 90%, 5% 100%); }
  .vignettes--2 {
    padding-left: 6rem;
    margin-top: 10rem;
    clip-path: polygon(5% 0, 100% 5%, 90% 100%, 5% 95%); }
  .vignettes__line {
    position: absolute;
    width: 2px;
    height: 20rem;
    padding: 0; }
    .vignettes__line::before, .vignettes__line::after {
      content: "";
      position: absolute;
      z-index: 10;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background-color: #ff916a; }
    .vignettes__line::before {
      top: 3.5rem;
      right: 4rem; }
    .vignettes__line::after {
      top: 8rem;
      right: -4.5rem; }
  @media only screen and (max-width: 37.5em) {
    .vignettes {
      flex-direction: column;
      margin-top: 2rem; }
      .vignettes > * {
        max-width: 55rem; }
      .vignettes--1 {
        margin-right: 10rem; }
      .vignettes--2 {
        margin-left: 10rem;
        margin-top: 1rem; }
      .vignettes__line {
        background-color: #ff916a; }
        .vignettes__line::before, .vignettes__line::after {
          display: none; } }

.heading {
  padding: 3rem; }
  .heading__subtitle {
    color: #ca2d7c;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500; }
  @media only screen and (max-width: 62.5em) {
    .heading {
      padding: 1rem; } }

.carousel {
  position: relative;
  display: flex;
  gap: 1rem;
  height: 40rem;
  width: 95vw;
  margin: 5rem auto; }
  .carousel__arrow {
    z-index: 20;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    color: #ff98cb;
    background-color: rgba(35, 35, 35, 0.8);
    backdrop-filter: blur(2px); }
    .carousel__arrow--left {
      left: 32%; }
    .carousel__arrow--right {
      right: 32%; }
  .carousel__slide {
    position: absolute;
    top: 0;
    flex-shrink: 0;
    border-radius: 3px;
    width: 50rem;
    transition: all 0.2s;
    box-shadow: 0 1.5rem 4rem rgba(35, 35, 35, 0.5);
    z-index: 1;
    left: 50%;
    transform: translateX(-50%) scale(0.4);
    pointer-events: none; }
    .carousel__slide--12 {
      z-index: 6;
      transform: translateX(-140%) scale(0.6); }
    .carousel__slide--13 {
      z-index: 8;
      transform: translateX(-105%) scale(0.8); }
    .carousel__slide--1 {
      z-index: 10;
      transform: translateX(-50%) scale(1);
      pointer-events: initial; }
    .carousel__slide--2 {
      z-index: 8;
      transform: translateX(5%) scale(0.8); }
    .carousel__slide--3 {
      z-index: 6;
      transform: translateX(40%) scale(0.6); }
  .carousel__img {
    width: 100%;
    height: auto; }
  .carousel__shortcuts {
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 1rem; }
    .carousel__shortcuts button {
      position: relative;
      background-color: #fff;
      border: none;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      transition-property: all 0.2s; }
    .carousel__shortcuts--hidden {
      position: absolute;
      left: -9999px; }
    .carousel__shortcuts--active {
      background-color: #ff53a9 !important;
      height: 2rem !important;
      width: 2rem !important;
      box-shadow: 0 0 0 5px rgba(255, 83, 169, 0.3); }
  @media (hover: none), (-moz-touch-enabled: 1), (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .carousel__arrow {
      display: none; } }

.project-card {
  border: none !important;
  position: relative;
  perspective: 150rem;
  -moz-perspective: 150rem;
  height: 55rem;
  width: 40rem;
  margin: 5rem 0;
  border-radius: 3px;
  font-size: 1.6rem;
  cursor: pointer; }
  .project-card__side {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.8s ease;
    box-shadow: 0 0.5rem 2rem rgba(35, 35, 35, 0.1);
    backface-visibility: hidden; }
    .project-card__side--back {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      transform: rotateY(180deg);
      color: #eee; }
      .project-card__side--back-1 {
        background-image: linear-gradient(to right bottom, #e34363, #b01030); }
      .project-card__side--back-2 {
        background-image: linear-gradient(to right bottom, #993399, #660066); }
      .project-card__side--back-3 {
        background-image: linear-gradient(to right bottom, #4c2771, #261439); }
  .project-card__picture {
    height: 50%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    background-size: cover;
    background-position: center;
    background-blend-mode: screen; }
    .project-card__picture-1 {
      background-image: linear-gradient(to right bottom, rgba(114, 16, 36, 0.8), rgba(212, 57, 88, 0.3)), url(../assets/projects/lilys-cuisine.webp); }
    .project-card__picture-2 {
      background-image: linear-gradient(to right bottom, rgba(86, 1, 134, 0.5), rgba(29, 0, 75, 0.5)), url(../assets/projects/mythical-books.webp); }
    .project-card__picture-3 {
      background-image: linear-gradient(to right bottom, rgba(86, 1, 134, 0.5), rgba(29, 0, 75, 0.5)), url(../assets/projects/go-scrum.webp); }
  .project-card__btn-source-code:link, .project-card__btn-source-code:visited {
    background-color: #2e2e2e;
    color: #8587ff;
    padding: 0.5rem 4rem;
    margin-top: 1rem;
    border-radius: 3px;
    transition: all 0.2s; }
  .project-card__btn-source-code:hover, .project-card__btn-source-code:focus {
    transform: translateY(-2px); }
  .project-card:hover .project-card__side--back {
    transform: rotateY(0); }
  .project-card:hover .project-card__side--front {
    transform: rotateY(-180deg); }
  .project-card__heading {
    position: absolute;
    top: 40%;
    right: 5%;
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: right;
    background-color: #232323;
    color: #fff;
    width: auto;
    padding: 1rem 2rem;
    border-radius: 3px; }
  .project-card__details {
    padding: 1rem; }
  .project-card__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.3rem;
    gap: 1rem; }
    .project-card__item:not(:last-child) {
      border-bottom: 1px solid #eee; }
    .project-card__item--first {
      color: #696969; }
    .project-card__item--second {
      color: #3f3f3f;
      font-weight: 500;
      text-transform: capitalize; }
  .project-card__instructions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    bottom: 4rem; }
    .project-card__instructions--text {
      background-color: #232323;
      border-radius: 3px;
      padding: 1rem 2rem;
      display: flex;
      align-items: baseline;
      gap: 1rem; }
      .project-card__instructions--text code {
        color: #ff75ba; }
  @media only screen and (max-width: 50em) {
    .project-card {
      height: 55rem;
      width: 80vw;
      margin: 1rem 0;
      font-size: 1.6rem; }
      .project-card__side--back {
        margin-top: -2rem;
        padding: 2rem 0 !important;
        flex-direction: row;
        gap: 2rem; }
      .project-card__btn-source-code:link, .project-card__btn-source-code:visited {
        padding: 1rem 4rem;
        margin-top: 0.5rem; }
      .project-card__list {
        font-size: 2rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr); }
      .project-card__item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.3rem;
        gap: 1rem; }
        .project-card__item:not(:last-child) {
          border-bottom: 1px solid #eee; }
        .project-card__item--first {
          color: #696969; }
        .project-card__item--second {
          color: #3f3f3f;
          font-weight: 500;
          text-transform: capitalize; }
      .project-card__instructions {
        position: absolute;
        bottom: 4rem; }
        .project-card__instructions--text {
          background-color: #232323;
          border-radius: 3px;
          padding: 1rem 2rem;
          display: flex;
          align-items: baseline;
          gap: 1rem; }
          .project-card__instructions--text code {
            color: #ff75ba; } }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    .project-card {
      overflow: hidden;
      height: 70rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0 0.5rem 2rem rgba(35, 35, 35, 0.1); }
      .project-card__picture {
        height: 80%; }
      .project-card__side {
        height: auto;
        position: relative;
        box-shadow: none; }
        .project-card__side--back {
          padding: 4rem 0;
          transform: rotateY(0);
          clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%); }
          .project-card__side--back h3 {
            display: none; }
      .project-card:hover .project-card__side--front {
        transform: rotateY(0); }
      .project-card__instructions {
        display: none; }
      .project-card__heading {
        top: 60%; } }
  @media only screen and (max-width: 70em) {
    .project-card {
      height: fit-content; }
      .project-card__instructions {
        display: none; }
      .project-card__side--front {
        position: relative;
        height: fit-content; }
      .project-card__picture {
        height: 8rem; }
        .project-card__picture-1 {
          background-image: linear-gradient(to right bottom, #e34363, #b01030); }
        .project-card__picture-2 {
          background-image: linear-gradient(to right bottom, #993399, #660066); }
        .project-card__picture-3 {
          background-image: linear-gradient(to right bottom, #4c2771, #261439); }
      .project-card__heading {
        text-align: center;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        font-size: 3rem;
        font-weight: 500;
        white-space: nowrap;
        width: fit-content;
        background-color: transparent; }
      .project-card__item {
        justify-content: flex-start; } }
  @media only screen and (max-width: 35em) {
    .project-card__list {
      font-size: 2rem;
      display: flex;
      flex-direction: column; } }
  @media only screen and (max-width: 28em) {
    .project-card {
      width: 90vw; } }
  @media only screen and (max-width: 23em) {
    .project-card__side--back {
      flex-direction: column;
      gap: 0.5rem;
      padding-top: 4rem !important; } }

.btn, .btn:link, .btn:visited {
  position: relative;
  text-transform: uppercase;
  padding: 1.5rem 4rem;
  border-radius: 1rem;
  transition: all 0.2s;
  font-size: 1.6rem;
  border: 3px solid #fff; }

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(35, 35, 35, 0.2); }
  .btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }

.btn:active, .btn:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(35, 35, 35, 0.2); }

.btn::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  transition: 0.4s; }

.btn--white {
  background-color: #fff;
  color: #4f4f4f; }
  .btn--white::after {
    background-color: #fff; }
  .btn--white:hover {
    color: #fff;
    background-color: rgba(35, 35, 35, 0.3); }

.btn--animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards; }

.btn-text:link, .btn-text:visited {
  color: #ff53a9;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #ff53a9;
  padding: 3px;
  font-size: 1.6rem;
  transition: all 0.2s; }

.btn-text:hover {
  background-color: #ff53a9;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(35, 35, 35, 0.15);
  transform: translateY(-2px); }

.btn-text:active {
  box-shadow: 0 0.5rem 1rem rgba(35, 35, 35, 0.15); }

.btn--orange {
  border: none;
  background: linear-gradient(150deg, #f08c00, #f59f00);
  color: white; }
  .btn--orange:hover, .btn--orange:focus {
    color: #f08c00;
    background: transparent;
    box-shadow: inset 0 0 0 3px #f59f00, 0 1rem 2rem rgba(35, 35, 35, 0.15); }
  .btn--orange:active {
    box-shadow: inset 0 0 0 3px #f59f00, 0 0.5rem 1rem rgba(35, 35, 35, 0.3); }

@media only screen and (max-width: 53.5em) {
  .btn, .btn:link, .btn:visited {
    padding: 0.5rem 4rem;
    font-size: 1.6rem; } }

.technologies {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 10rem;
  width: 70vw;
  min-height: 40rem;
  flex-direction: column;
  background-size: cover !important; }
  @media only screen and (max-width: 87.5em) {
    .technologies {
      width: 90vw; } }
  @media only screen and (max-width: 28.5em) {
    .technologies {
      padding: 4rem 5rem; } }
  .technologies--shape-left {
    clip-path: polygon(0 0, 95% 0, 100% 100%, 5% 100%); }
  .technologies--shape-right {
    clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%); }
  .technologies--image-one {
    background: linear-gradient(to right, rgba(44, 18, 54, 0.5), rgba(36, 46, 26, 0.6)), url("/src/assets/backgrounds/technologies-background-one.webp");
    background-position: bottom; }
  .technologies--image-two {
    background: linear-gradient(to right, rgba(18, 37, 54, 0.5), rgba(36, 46, 26, 0.6)), url("/src/assets/backgrounds/technologies-background-two.webp");
    background-position: center; }
  .technologies--image-three {
    background: linear-gradient(to bottom, rgba(10, 19, 48, 0.6), rgba(196, 137, 28, 0.2)), url("/src/assets/backgrounds/technologies-background-three.webp");
    background-position: center; }
  .technologies__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3rem;
    pointer-events: none;
    padding: 0 5rem; }
    .technologies__list:hover > * {
      filter: blur(3px) grayscale(100%); }
  .technologies__item {
    pointer-events: auto;
    position: relative;
    transition: all 0.3s;
    cursor: pointer; }
    .technologies__item:hover {
      filter: blur(0) grayscale(0%) !important;
      z-index: 100;
      transform: scale(1.2); }
    .technologies__item:hover .technologies__item--text {
      width: max-content;
      padding-left: 3rem;
      color: #fff;
      opacity: 1; }
    .technologies__item--img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 10;
      padding: 2rem;
      border-radius: 50%;
      background-color: #232323; }
    .technologies__item--text {
      position: absolute;
      top: 50%;
      left: 70%;
      z-index: 1;
      font-weight: 600;
      text-transform: uppercase;
      transform: translateY(-50%);
      transition: all 0.4s;
      background-color: rgba(255, 83, 169, 0.8);
      color: transparent;
      opacity: 0;
      display: inline-block;
      width: 0;
      padding: 0.5rem;
      border-radius: 3px; }
      .technologies__item--text:hover {
        display: none !important; }
  @media only screen and (max-width: 112.5em) {
    .technologies__list {
      gap: 1rem;
      padding: 0; }
    .technologies__item--img {
      width: 4rem !important;
      height: 4rem !important; }
    .technologies__item--text {
      position: absolute;
      top: 50%;
      left: 70%;
      z-index: 1;
      font-weight: 600;
      text-transform: uppercase;
      transform: translateY(-50%);
      transition: all 0.4s 0.2s;
      background-color: #ff53a9;
      color: transparent;
      opacity: 0;
      display: inline-block;
      width: 0;
      padding: 0.5rem;
      border-radius: 3px; }
      .technologies__item--text:hover {
        display: none !important; } }
  @media only screen and (max-width: 28.5em) {
    .technologies {
      clip-path: none; } }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    .technologies__filter-on {
      filter: none !important; }
    .technologies__item {
      position: relative;
      transition: all 0.3s;
      cursor: pointer; }
      .technologies__item:hover {
        transform: none !important; }
      .technologies__item:hover .technologies__item--text {
        display: none !important; } }

.thin-line {
  position: relative;
  height: 2px;
  background-color: #eee;
  margin: 4rem; }
  .thin-line::before, .thin-line::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #ff916a; }
  .thin-line::before {
    left: -3rem; }
  .thin-line::after {
    right: -3rem; }
  .thin-line--small {
    width: 60%; }
  .thin-line--big {
    width: 100%; }

.overlay {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw; }
  .overlay ::-webkit-scrollbar {
    width: 0.8rem; }

.backdrop {
  cursor: pointer;
  height: 100%;
  width: 100%;
  background-color: rgba(35, 35, 35, 0.8);
  backdrop-filter: blur(4px); }

.certifications-modal {
  z-index: 25000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 85%;
  width: 80%;
  border-radius: 3px;
  padding: 1rem;
  background-color: #fff; }
  @media only screen and (max-height: 50em) {
    .certifications-modal {
      height: 90%; } }
  .certifications-modal__date {
    margin-bottom: 2rem;
    font-size: 2.3rem;
    text-align: center;
    color: #b3664a; }
  .certifications-modal__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 4rem;
    width: 4rem;
    font-size: 3rem;
    border-radius: 50%;
    color: #a7a7a7;
    background-color: rgba(255, 255, 255, 0.8);
    transition: all 0.2s; }
    .certifications-modal__btn:hover {
      color: #cc4287; }
  .certifications-modal__content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 9fr 1fr;
    gap: 1rem;
    overflow: auto;
    padding: 1rem; }
    .certifications-modal__content::-webkit-scrollbar-track {
      margin-top: 5rem; }
  .certifications-modal__img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    padding: 1rem; }
    .certifications-modal__img img {
      width: 100%; }
    .certifications-modal__img--coderhouse {
      filter: invert(100%) hue-rotate(120deg); }
  .certifications-modal__info {
    grid-column: 2/ -1;
    grid-row: 1/ -1; }
  .certifications-modal__title {
    font-size: 4rem;
    margin-bottom: -2.5rem;
    text-align: center;
    font-family: "Quintessential", cursive; }
  .certifications-modal__cta {
    display: flex;
    justify-content: center;
    align-items: center; }
    .certifications-modal__cta a:link,
    .certifications-modal__cta a:visited {
      color: #fff;
      background-color: #f59f00;
      border: 3px solid #f59f00;
      border-radius: 3px;
      font-size: 1.6rem;
      padding: 1rem;
      transition: all 0.2s; }
    .certifications-modal__cta a:hover {
      transform: translateY(-3px);
      background-color: transparent;
      color: #f59f00; }
    .certifications-modal__cta a.disabled {
      background-color: #bdbdbd;
      border-color: #bdbdbd;
      pointer-events: none; }
      .certifications-modal__cta a.disabled:hover {
        transform: none;
        background-color: #bdbdbd;
        color: #fff; }
  .certifications-modal__text {
    height: 70%;
    overflow: auto;
    padding: 0 4rem;
    font-size: 1.6rem;
    color: #4f4f4f;
    letter-spacing: 0.2rem; }
    .certifications-modal__text p {
      margin-bottom: 2.5rem; }
  .certifications-modal h4 {
    text-align: center; }
  @media only screen and (max-width: 75em) {
    .certifications-modal {
      height: fit-content; }
      .certifications-modal__info {
        height: fit-content;
        overflow: auto; }
      .certifications-modal__text {
        font-size: 1.9rem;
        margin-bottom: 2rem;
        height: auto; }
      .certifications-modal__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .certifications-modal__img {
        display: none; } }

.complexity {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1.5rem; }
  .complexity__point {
    height: 0.7rem;
    width: 5rem;
    background-color: #eee;
    border-radius: 3px;
    transform: skewX(-50deg); }

.language-btn__wrapper {
  position: relative; }

.language-btn__opts {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4rem;
  left: 0rem;
  font-size: 1.6rem;
  background-color: #fff;
  color: #4f4f4f;
  border-radius: 7px;
  overflow: hidden;
  transition: all 0.2s;
  height: 0;
  opacity: 0; }
  .language-btn__opts--visible {
    height: fit-content;
    opacity: 1; }
  .language-btn__opts--selected {
    background-color: rgba(35, 35, 35, 0.08);
    color: #919191 !important;
    border-left-color: transparent !important; }
  .language-btn__opts button {
    padding: 1rem;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    transition: all 0.2s;
    text-transform: capitalize;
    color: #393939;
    background-color: transparent; }
    .language-btn__opts button:first-child {
      border-top-left-radius: 7px; }
    .language-btn__opts button:last-child {
      border-bottom-left-radius: 7px; }
    .language-btn__opts button:not(:last-child) {
      border-bottom: 1px solid rgba(35, 35, 35, 0.1); }
    .language-btn__opts button:hover {
      border-left-color: #ff53a9;
      color: #ff53a9; }

@media only screen and (max-width: 62.5em) {
  .language-btn__opts {
    top: 0rem;
    left: 7rem; } }

.theme-btn__wrapper {
  position: relative; }

.theme-btn__msg {
  z-index: 100;
  position: absolute;
  bottom: -4rem;
  font-size: 1.2rem;
  border-radius: 3px;
  white-space: nowrap;
  opacity: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  color: #232323;
  background-color: #fff;
  transition: bottom 0.5s, height 0.1s, opacity 0.3s; }
  .theme-btn__msg--visible {
    height: fit-content;
    padding: 0.15rem 0.5rem;
    animation-name: fade-in-out;
    animation-duration: 3s;
    animation-timing-function: ease; }

.logo {
  z-index: 2;
  position: relative;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.4s; }
  .header--shrinked .logo {
    width: 5rem;
    height: 5rem; }
    .header--shrinked .logo__options {
      right: -8rem;
      gap: 0.75rem; }
      .header--shrinked .logo__options button {
        font-size: 2rem; }
  .logo__wrapper {
    position: relative; }
  .logo__options {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: rgba(245, 159, 0, 0.5);
    transition: all 0.2s; }
    .logo__options--btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;
      font-size: 2.5rem;
      border: none;
      background-color: transparent;
      color: #fff;
      transition: all 0.2s;
      opacity: 0.8; }
      .logo__options--btn:hover {
        opacity: 1; }
  .logo img {
    height: 100%;
    object-fit: cover; }
  @media only screen and (max-width: 62.5em) {
    .logo {
      width: 8rem;
      height: 8rem; }
    .logo__wrapper {
      z-index: 1000;
      position: relative;
      padding-bottom: 14rem; }
    .logo__options {
      top: 0;
      right: 50%;
      transform: translateX(50%);
      flex-direction: column;
      width: 8rem;
      padding: 2rem;
      padding-top: 10rem;
      border-radius: 20rem;
      background-color: rgba(35, 35, 35, 0.8);
      backdrop-filter: blur(1px);
      -webkit-backdrop-filter: blur(1px); }
      .logo__options button {
        font-size: 3rem;
        color: #f59f00;
        opacity: 1; } }
  @media (max-width: 37.5em) {
    .logo__wrapper {
      padding: 0; }
    .logo__options {
      flex-direction: row;
      top: 50%;
      left: 7rem;
      transform: translateY(-50%);
      right: -10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 0.5rem 2rem;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      transition: all 0.2s;
      width: fit-content; } }

.languages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .languages__title {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 3rem; }
  .languages__list {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 3px;
    pointer-events: none;
    column-gap: 2rem;
    row-gap: 1rem; }
    .languages__list:hover > * {
      filter: blur(1px) grayscale(100%); }
  .languages__item {
    padding: 5rem 18rem;
    transition: all 0.4s;
    pointer-events: auto; }
    .languages__item--flag {
      position: relative;
      font-size: 2rem;
      margin-bottom: -1rem;
      padding-left: 2rem; }
      .languages__item--flag span {
        position: absolute;
        bottom: 0;
        left: 8rem;
        color: white;
        font-size: 3rem;
        text-shadow: 2px 2px 7px #232323; }
    .languages__item--level p {
      text-align: end;
      padding-right: 2rem;
      font-size: 1.4rem;
      text-transform: uppercase;
      margin-bottom: -1rem;
      color: white; }
    .languages__item--1 {
      background: linear-gradient(to bottom, rgba(35, 35, 35, 0.4), rgba(35, 35, 35, 0.8)), url("../assets/backgrounds/spain-background.webp");
      clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 0% 100%);
      border-top-left-radius: 20rem;
      margin-right: -5.5rem; }
    .languages__item--2 {
      background: linear-gradient(to bottom, rgba(35, 35, 35, 0.2), rgba(35, 35, 35, 0.6)), url("../assets/backgrounds/usa-background.webp");
      clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
      border-top-right-radius: 20rem;
      margin-left: -5.5rem; }
    .languages__item--3 {
      background: linear-gradient(to bottom, rgba(35, 35, 35, 0.4), rgba(35, 35, 35, 0.7)), url("../assets/backgrounds/italy-background.webp");
      clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 0% 100%);
      border-bottom-left-radius: 20rem;
      margin-right: -5.5rem; }
    .languages__item--4 {
      background: linear-gradient(to bottom, rgba(35, 35, 35, 0.4), rgba(35, 35, 35, 0.9)), url("../assets/backgrounds/japan-background.webp");
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 15% 100%);
      border-bottom-right-radius: 20rem;
      margin-left: -5.5rem; }
    .languages__item--1, .languages__item--2, .languages__item--3, .languages__item--4 {
      background-size: 100%;
      background-position: center; }
    .languages__item img {
      width: 5rem;
      height: 5rem; }
    .languages__item:hover {
      filter: none;
      background-size: 110%; }
  @media only screen and (max-width: 68em) {
    .languages__item {
      padding: 5rem 15rem; }
      .languages__item--1, .languages__item--3 {
        margin-right: -5rem; }
      .languages__item--2, .languages__item--4 {
        margin-left: -5rem; } }
  @media only screen and (max-width: 50em) {
    .languages__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80vw; }
    .languages__item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 5rem;
      width: 90%; }
      .languages__item--1 {
        border-top-left-radius: 10rem;
        border-top-right-radius: 10rem; }
      .languages__item--2, .languages__item--3 {
        border-radius: 0; }
      .languages__item--4 {
        border-bottom-right-radius: 10rem;
        border-bottom-left-radius: 10rem; }
      .languages__item--1, .languages__item--2, .languages__item--3, .languages__item--4 {
        clip-path: none;
        margin: 0; }
      .languages__item--flag {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-bottom: 0;
        padding-left: 0; }
        .languages__item--flag span {
          position: relative;
          left: 0; } }
  @media only screen and (max-width: 33em) {
    .languages__item {
      padding: 3rem;
      flex-direction: column; }
      .languages__item--flag img {
        width: 4rem;
        height: 4rem; }
      .languages__item--level p {
        display: none; } }
  @media only screen and (max-width: 27em) {
    .languages__item {
      background-size: 150%; }
      .languages__item:hover {
        background-size: 170%; } }

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 3rem;
  position: fixed;
  z-index: 999;
  width: 100%;
  padding: 2rem 10rem;
  transition: all 0.5s;
  background-color: transparent; }
  .header--shrinked {
    background-color: rgba(35, 35, 35, 0.98);
    padding: 0.75rem 10rem; }
    .header--shrinked .header__logo {
      width: 5rem;
      height: 5rem; }
    .header--shrinked .header__nav {
      font-size: 1.6rem; }
  .header__logo {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    overflow: hidden;
    transition: all 0.4s; }
    .header__logo img {
      height: 100%;
      object-fit: cover; }
  .header__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    font-size: 2rem; }
    .header__nav--hidden {
      display: none; }
    .header__nav--list {
      display: flex;
      justify-content: space-evenly;
      gap: 4rem;
      padding: 0; }
    .header__nav--item {
      position: relative;
      transition: all 0.2s ease-in-out; }
      .header__nav--item:before {
        content: "";
        position: absolute;
        bottom: -8px;
        background-color: #f59f00;
        opacity: 0;
        width: 0;
        height: 2px;
        transition: all 0.3s ease;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px; }
      .header__nav--item:hover:before {
        width: 100%;
        opacity: 1; }
    .header__nav--link:link, .header__nav--link:visited {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      color: #fff; }
    .header__nav--link:hover {
      color: #f59f00; }
    .header__nav--span {
      color: #fff; }
    .header__nav--bars {
      display: none; }
  .header__sidebar-wrapper {
    position: relative; }
  .header__icon-list {
    z-index: 1;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 1rem 0;
    padding-top: 8rem;
    width: 8rem;
    height: 50rem;
    font-size: 3rem;
    border-radius: 20rem;
    background-color: rgba(35, 35, 35, 0.8);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    overflow: hidden;
    transition: all 0.3s; }
    .header__icon-list a {
      color: #f59f00; }
    .header__icon-list--hidden {
      height: 0;
      opacity: 0; }
  .header__logo {
    position: relative;
    z-index: 2; }
  @media only screen and (max-width: 62.5em) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1.5rem;
      padding: 2rem 3rem;
      width: 15rem;
      background-color: transparent;
      transition: color 0s width 3s ease; }
      .header__logo {
        width: 8rem;
        height: 8rem; }
      .header--opened-nav {
        width: 100vw;
        height: 100vh; }
      .header__nav {
        position: absolute;
        top: 0;
        left: 0;
        left: -1000%;
        width: 100vw;
        height: 100vh;
        display: flex;
        transition: all 1s;
        background-color: rgba(35, 35, 35, 0.9);
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px); }
        .header__nav--overlay {
          transform: translateX(-50%);
          left: 50%; }
        .header__nav--bars {
          z-index: 2;
          position: relative;
          cursor: pointer;
          font-size: 5rem;
          color: #fff;
          background: rgba(255, 83, 169, 0.8);
          width: 8rem;
          height: 8rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center; }
        .header__nav--list {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          height: 80%;
          font-size: 5rem; }
        .header__nav--link:link {
          display: flex;
          gap: 4rem; }
        .header__nav--link i {
          color: #f59f00; } }
  @media (max-width: 37.5em) {
    .header {
      flex-direction: row-reverse;
      justify-content: flex-end; }
      .header__icon-list {
        display: none; } }
  @media screen and (max-height: 30em) and (max-width: 62.5em) {
    .header__icon-list {
      display: none; }
    .header__nav--list {
      align-self: baseline;
      gap: 0;
      justify-content: space-between;
      height: 90%;
      font-size: 3rem; } }
  @media screen and (max-height: 30em) and (max-width: 62.5em) {
    .header__nav--list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem; } }

.hero {
  min-height: 100vh;
  color: #fff;
  position: relative;
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  background: url("/src/assets/backgrounds/background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.6rem; }
  .hero::after, .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 75%;
    height: 100%;
    background-image: linear-gradient(120deg, transparent 0%, #290e29 30%, #161658 80%, transparent 100%);
    opacity: 0.3; }
    @media only screen and (max-width: 37.5em) {
      .hero::after, .hero::before {
        width: 200%; } }
  .hero::before {
    transform: skew(-15deg) translateX(-50%);
    animation: moveInLeftSpecial 1.5s ease; }
  .hero::after {
    transform: skew(15deg) translateX(-50%);
    animation: moveInRightSpecial 1.5s ease; }
  .hero > * {
    z-index: 100; }
  .hero__text--greeting {
    font-size: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: -0.7rem;
    opacity: 0;
    animation: moveInTop 1s ease-out 1.7s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
    .hero__text--greeting::before {
      content: "";
      width: 2rem;
      height: 2px;
      background-color: #ff53a9; }
  .hero__text--professions {
    display: flex;
    gap: 1rem;
    font-size: 3rem;
    white-space: nowrap;
    width: 30rem;
    opacity: 0;
    margin-top: -1rem;
    animation: moveInRight 1s ease-out 2.7s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
    .hero__text--professions-typing {
      color: #ff53a9; }

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border-top: 2px solid #e69500;
  background-color: #232323;
  color: #fff; }
  .footer__text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    gap: 1rem; }
  .footer__link:link, .footer__link:visited {
    position: relative;
    transition: all 0.2s ease-in-out;
    color: #fff; }
  .footer__link::before {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    height: 2px;
    width: 0;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    transition-duration: 0.45s;
    background-color: #c47f00;
    border-bottom-right-radius: 0.7rem;
    border-bottom-left-radius: 0.7rem; }
  .footer__link:hover::before {
    width: 100%;
    opacity: 1; }
  .footer__link:hover .footer__icon--react {
    animation: spin 3s linear infinite; }
  .footer__icon {
    display: inline-block;
    padding: 0 1rem !important; }
    .footer__icon--heart {
      cursor: pointer;
      color: #dc143c;
      transition: all 0.5s; }
      .footer__icon--heart:hover {
        animation: heartbeats 1s linear infinite; }
    .footer__icon--react {
      color: #61dbfb; }
  .footer__icons8 {
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
    font-size: 1.6rem; }
    .footer__icons8 a:link, .footer__icons8 a:visited {
      display: flex;
      align-items: center;
      color: #1fb141;
      transition: all 0.2s; }
    .footer__icons8 a:hover {
      color: #167c2e; }
    .footer__icons8 img {
      width: 2rem; }

.section-about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 2rem;
  margin: 2rem;
  border: 1px solid #ddd; }

.section-courses {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 2rem;
  background-image: linear-gradient(to right bottom, rgba(35, 35, 35, 0.7), rgba(35, 35, 35, 0.8)), url("../assets/backgrounds/courses-background.webp");
  background-size: cover;
  background-position: center; }

.section-projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 10rem;
  margin: 0 auto; }
  .section-projects__list {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    width: 100%; }
    @media only screen and (max-width: 50em) {
      .section-projects__list {
        flex-direction: column; } }
  .section-projects__cta {
    font-size: 2.5rem; }
    .section-projects__cta--btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      font-weight: 500;
      box-shadow: 0 1rem 2rem rgba(35, 35, 35, 0.2); }
    .section-projects__cta--icon {
      font-size: 2.5rem; }
    .section-projects__cta button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem; }

.section-contact {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
  padding: 5rem 2rem;
  color: #fff;
  background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.65)), url("../assets/backgrounds/contact-background.webp");
  background-size: cover;
  background-position: center; }
  .section-contact__text--title {
    font-size: 6rem !important; }
  .section-contact__text--subtitle {
    margin: -0.5rem;
    font-weight: 500;
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #ff53a9; }
  .section-contact__text--comment {
    font-size: 2.5rem;
    font-weight: 300; }
  .section-contact__list {
    display: flex;
    gap: 3rem;
    font-size: 1.3rem; }
    @media only screen and (max-width: 62.5em) {
      .section-contact__list {
        flex-direction: column;
        margin-top: 5rem; } }
  .section-contact__item {
    display: flex;
    align-items: center;
    gap: 1rem; }
  .section-contact__link:link, .section-contact__link:visited {
    font-size: 2.5rem;
    color: #f59f00;
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: all 0.4s;
    padding: 1rem;
    border-radius: 2rem; }
  .section-contact__link:hover {
    background-color: rgba(35, 35, 35, 0.8);
    color: #fff;
    transform: scale(1.1) translateY(-1rem);
    box-shadow: rem 1rem 1rem 3px rgba(35, 35, 35, 0.2); }
  .section-contact__link:hover i,
  .section-contact__link i {
    color: #f59f00 !important; }

.certifications {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 6rem; }
  .certifications__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
    gap: 6rem;
    padding: 2rem; }
  .certifications__image-box {
    position: relative;
    cursor: pointer;
    overflow: hidden; }
    .certifications__image-box img {
      width: 100%; }
    .certifications__image-box--coderhouse img {
      filter: invert(100%) hue-rotate(120deg); }
    .certifications__image-box::before, .certifications__image-box::after {
      content: "";
      z-index: 10;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      transform: skewX(-53.5deg);
      transition: all 0.7s; }
    .certifications__image-box::before {
      background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 100%);
      left: -200%; }
    .certifications__image-box::after {
      background: linear-gradient(to left, rgba(35, 35, 35, 0.1) 0%, rgba(35, 35, 35, 0.2) 100%);
      right: -200%; }
    .certifications__image-box:hover::before {
      left: -50%; }
    .certifications__image-box:hover::after {
      right: -50%; }
    .certifications__image-box img {
      max-width: 50rem; }
  .certifications__zoom {
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #ff6db6;
    opacity: 0;
    font-size: 6rem;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s ease-out 0s; }
  .certifications__image-box:hover .certifications__zoom {
    opacity: 1; }
  @media only screen and (max-width: 75em) {
    .certifications__container {
      gap: 2rem; } }
  @media only screen and (max-width: 50em) {
    .certifications {
      padding: 2rem; }
      .certifications__container {
        grid-template-columns: repeat(2, 1fr); }
      .certifications img {
        width: 100%;
        max-width: 100%; } }

body .dark {
  background-color: #232323;
  color: #fff; }

.dark .section-about {
  border: 1px solid rgba(255, 255, 255, 0.1); }

.dark .project-card {
  background-color: #232323; }
  .dark .project-card__side--front {
    background-color: #262641; }
  .dark .project-card__item--first {
    color: rgba(255, 255, 255, 0.6); }
  .dark .project-card__item--second {
    color: #fff; }
  .dark .project-card__item:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    .dark .project-card {
      background-color: #262641; } }

.dark .thin-line {
  background-color: rgba(255, 255, 255, 0.1); }
  .dark .thin-line::before, .dark .thin-line::after {
    background-color: #cc7455; }

.dark .vignettes {
  color: white; }
  .dark .vignettes > * {
    background-color: #262641; }
  .dark .vignettes__line {
    background-color: rgba(255, 255, 255, 0.1); }
    .dark .vignettes__line::before, .dark .vignettes__line::after {
      background-color: #cc7455; }

.dark .traits__card {
  background-color: rgba(35, 35, 35, 0.9);
  color: white; }
  .dark .traits__card--icon {
    filter: grayscale(0%);
    opacity: 1; }
    .dark .traits__card--icon-2 {
      color: #e34363; }
    .dark .traits__card--icon-3 {
      color: #993399; }

.dark .certifications-modal {
  background-color: #262641; }
  .dark .certifications-modal__date {
    color: #f8bc4d; }
  .dark .certifications-modal__btn {
    color: #b3b3b3;
    background-color: rgba(35, 35, 35, 0.8); }
    .dark .certifications-modal__btn:hover {
      color: #cc4287; }
  .dark .certifications-modal__img img {
    filter: invert(100%) hue-rotate(180deg); }
  .dark .certifications-modal__img--coderhouse {
    filter: none !important; }
  .dark .certifications-modal__text {
    color: white; }

.dark .language-btn__opts {
  background-color: #232323; }
  .dark .language-btn__opts--selected {
    background-color: rgba(255, 255, 255, 0.05);
    color: #b3b3b3; }
  .dark .language-btn__opts button {
    color: #fff; }
  .dark .language-btn__opts button:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }

.dark .carousel__arrow {
  color: #ff53a9;
  background-color: rgba(35, 35, 35, 0.3); }

.dark .carousel__img {
  filter: invert(90%) hue-rotate(180deg); }

.dark .certifications__image-box img {
  filter: invert(100%) hue-rotate(180deg); }

.dark .certifications__image-box--coderhouse img {
  filter: none !important; }
